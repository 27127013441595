var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("heir-desc1")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.heir.name ? _vm.wasiat.heir.name : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.heir.relationship ? _vm.wasiat.heir.relationship : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.heir.mobile_number ? _vm.wasiat.heir.mobile_number : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "input-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-") + " ")])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitHeirForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }